import { render, staticRenderFns } from "./sales.vue?vue&type=template&id=34bae923&scoped=true&"
import script from "./sales.vue?vue&type=script&lang=js&"
export * from "./sales.vue?vue&type=script&lang=js&"
import style0 from "./sales.vue?vue&type=style&index=0&id=34bae923&prod&lang=css&"
import style1 from "./sales.vue?vue&type=style&index=1&id=34bae923&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34bae923",
  null
  
)

export default component.exports